<template>
  <svg
    viewBox="0 0 58 52"
    class="stroke-[3px]"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9999 23.3333H9.26659C7.77311 23.3333 7.02638 23.3333 6.45594 23.624C5.95418 23.8796 5.54623 24.2876 5.29057 24.7894C4.99992 25.3598 4.99992 26.1065 4.99992 27.6V50M40.9999 23.3333H48.7333C50.2267 23.3333 50.9735 23.3333 51.5439 23.624C52.0457 23.8796 52.4536 24.2876 52.7093 24.7894C52.9999 25.3598 52.9999 26.1065 52.9999 27.6V50M40.9999 50V10.5333C40.9999 7.54639 40.9999 6.05291 40.4186 4.91205C39.9073 3.90852 39.0914 3.09262 38.0879 2.5813C36.947 2 35.4535 2 32.4666 2H25.5333C22.5463 2 21.0528 2 19.912 2.5813C18.9084 3.09262 18.0925 3.90852 17.5812 4.91205C16.9999 6.05291 16.9999 7.54639 16.9999 10.5333V50M55.6666 50H2.33325M26.3333 12.6667H31.6666M26.3333 23.3333H31.6666M26.3333 34H31.6666"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
