<template>
  <svg
    viewBox="0 0 33 40"
    fill="none"
    class="stroke-[1.5px]"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 4C1.25 2.20508 2.70508 0.75 4.5 0.75H20.5C20.6212 0.75 20.7375 0.798159 20.8232 0.883885L31.6161 11.6768C31.7018 11.7625 31.75 11.8788 31.75 12V36C31.75 37.7949 30.2949 39.25 28.5 39.25H4.5C2.70507 39.25 1.25 37.7949 1.25 36V4Z"
      stroke="currentColor"
    />
    <path
      d="M20.5 0.5V8C20.5 10.2091 22.2909 12 24.5 12H32"
      stroke="currentColor"
    />
  </svg>
</template>
