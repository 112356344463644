<template>
  <svg viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.625 4.10937L4.125 6.60937L9.125 1.60938"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
