<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
      fill-opacity="0.09"
    />
    <path
      d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="4"
    />
    <g clip-path="url(#clip0_29376_16284)">
      <path
        d="M16 18.6667V16M16 13.3334H16.0067M22.6667 16C22.6667 19.6819 19.6819 22.6667 16 22.6667C12.3181 22.6667 9.33337 19.6819 9.33337 16C9.33337 12.3181 12.3181 9.33337 16 9.33337C19.6819 9.33337 22.6667 12.3181 22.6667 16Z"
        stroke="white"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_29376_16284">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>
