<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 10.6667V14M8.00016 10.6667L12.0002 14M8.00016 10.6667L4.00016 14M14.0002 2V7.46667C14.0002 8.58677 14.0002 9.14682 13.7822 9.57465C13.5904 9.95097 13.2845 10.2569 12.9081 10.4487C12.4803 10.6667 11.9203 10.6667 10.8002 10.6667H5.20016C4.08006 10.6667 3.52001 10.6667 3.09218 10.4487C2.71586 10.2569 2.4099 9.95097 2.21815 9.57465C2.00016 9.14682 2.00016 8.58677 2.00016 7.46667V2M5.3335 6V8M8.00016 4.66667V8M10.6668 7.33333V8M14.6668 2H1.3335"
      stroke="currentColor"
      stroke-opacity="0.4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
