<template>
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 7.81301V8.42634C14.6658 9.86396 14.2003 11.2628 13.3395 12.4142C12.4787 13.5657 11.2688 14.408 9.89016 14.8156C8.51154 15.2232 7.03809 15.1743 5.68957 14.6761C4.34104 14.1779 3.18969 13.2571 2.40723 12.0511C1.62476 10.845 1.25311 9.41838 1.3477 7.98388C1.44229 6.54939 1.99806 5.18389 2.93211 4.09106C3.86615 2.99822 5.12844 2.2366 6.53071 1.91977C7.93298 1.60295 9.4001 1.7479 10.7133 2.33301M14.6666 3.08919L7.99992 9.76253L5.99992 7.76253"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
