<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    fill="none"
  >
    <path
      d="M8.86854 9.05736C9.10789 9.12025 9.35578 9.17825 9.60978 9.23259V0.555689H7.95085C5.07564 0.486084 3.93203 0.835333 3.05524 1.36959C2.96976 1.95025 2.88489 3.08714 3.2433 4.32111C3.92104 6.65657 5.81383 8.25018 8.86915 9.05736H8.86854Z"
      fill="url(#paint0_linear_20108_13094)"
    />
    <path
      d="M19.8432 0.554688H16.6328V10.8032C16.7012 10.8276 16.7696 10.8521 16.838 10.8777C18.5757 11.5304 21.087 12.8254 23.1867 15.2891C24.2113 16.4913 25.0185 17.8352 25.6065 19.3098V6.31853C25.6065 3.13559 23.0262 0.554688 19.8426 0.554688H19.8432Z"
      fill="url(#paint1_linear_20108_13094)"
    />
    <path
      d="M8.56698 10.1998C6.15459 9.5624 3.0724 8.07259 2.09242 4.59536C1.883 3.85351 1.80789 3.14951 1.79874 2.54688C0.923169 3.55799 0.392578 4.87561 0.392578 6.31841V23.6918C0.392578 26.8747 2.97288 29.4556 6.15642 29.4556H9.61045V21.4583L9.619 19.9575C9.619 19.9575 9.02369 10.3207 8.56759 10.1998H8.56698Z"
      fill="url(#paint2_linear_20108_13094)"
    />
    <path
      d="M16.4219 12.5012L16.6325 22.2399V29.4551H19.8429C20.6843 29.4551 21.4836 29.275 22.204 28.9508C22.2028 28.9544 22.2016 28.9569 22.2016 28.9569C23.0045 28.6119 23.62 28.1161 24.0877 27.5904C24.5474 27.0903 24.8674 26.7643 25.1922 26.1446C25.2123 26.1067 25.2337 26.0713 25.2606 26.0255C25.2856 25.9828 25.3227 25.9052 25.3491 25.8454C25.3632 25.8136 25.3988 25.7261 25.4141 25.6944C25.4907 25.5137 25.5095 25.4456 25.5614 25.2667C25.5654 25.2531 25.5951 25.1375 25.5976 25.1132V25.1096C25.3161 16.1793 16.4225 12.5 16.4225 12.5V12.5012H16.4219Z"
      fill="url(#paint3_linear_20108_13094)"
    />
    <path
      opacity="0.2"
      d="M7.97015 0.550961C7.97015 0.550961 2.11044 3.19232 5.80504 7.72646C5.80504 7.72646 2.50671 6.08828 3.04096 1.38196C3.04096 1.38196 4.01789 0.42213 7.96954 0.550961H7.97015Z"
      fill="url(#paint4_linear_20108_13094)"
    />
    <path
      opacity="0.2"
      d="M24.9978 23.4596C24.6193 21.7646 23.7742 20.1759 23.0256 18.622C22.6422 17.8264 22.2881 17.0388 21.7868 16.3079C21.6097 16.0497 21.432 15.7938 21.2647 15.5325C19.9697 14.4548 18.6771 13.7001 17.6019 13.1885C17.4315 13.1073 17.1702 13.0065 17.0005 12.9333C14.6736 11.9228 12.5408 11.6724 10.5687 11.3916C9.1509 11.1895 7.81007 10.9837 6.55534 10.5746C3.06223 8.88636 2.11828 5.61245 1.733 3.89062C1.733 3.89307 1.69698 3.95962 1.64569 4.05426C1.57059 4.18553 1.45641 4.38641 1.33552 4.62515C1.29095 4.70697 1.26103 4.76192 1.26042 4.76375C1.1322 5.03118 0.941697 5.45004 0.891019 5.7193C0.875755 5.77425 0.861711 5.82921 0.848889 5.88355C0.810423 6.05207 0.775619 6.2212 0.743259 6.39033C0.676706 6.68585 0.610764 6.98197 0.599774 7.05524C0.596721 7.07661 0.608933 7.26101 0.596111 7.3007C0.554591 7.69635 0.514904 8.09078 0.484375 8.48705V15.9794C0.815918 16.7585 2.28619 18.4462 2.94927 18.945C3.80042 19.5855 4.53982 20.1295 5.53384 20.5221C7.91326 21.4612 10.4826 21.5351 13.0036 21.7134C15.2744 21.8733 17.6343 22.3447 19.8903 23.1122C20.2823 23.2685 22.9597 24.3956 24.2364 25.1796C24.6376 25.408 25.0222 25.6107 25.3977 25.8256C25.3123 25.3884 25.2762 24.9531 25.2604 24.5165C25.1553 24.1722 25.0772 23.8162 24.9972 23.4596H24.9978Z"
      fill="url(#paint5_linear_20108_13094)"
    />
    <path
      d="M25.485 24.7636C25.4814 24.578 25.435 24.3966 25.4167 24.2104C25.4057 24.0974 25.4832 23.9961 25.5821 23.9454C25.5663 23.783 25.5492 23.6181 25.5492 23.6181C25.4893 22.9709 25.4728 23.0014 25.3196 22.2657C24.6766 19.1817 23.2448 17.1253 21.4882 15.4352C19.9471 13.9527 18.334 12.9788 17.0438 12.3646C16.8784 12.2858 16.625 12.1881 16.4601 12.1167C14.2016 11.1361 12.1885 10.7325 10.2744 10.4596C8.79921 10.2496 7.41015 10.0352 6.11755 9.57853C1.78002 8.04598 1.71591 4.40084 1.69759 3.17969C1.67927 3.25296 1.04122 4.22683 0.83912 5.114C0.780505 5.37227 0.514293 7.25651 0.484375 7.64117V15.7044C2.51332 17.8256 5.1846 18.7189 7.72154 19.0541C11.3563 19.534 13.7644 20.0395 16.9125 20.8327C19.7786 21.555 23.058 22.985 25.4857 24.7691C25.4857 24.7672 25.485 24.7654 25.485 24.763V24.7636Z"
      fill="url(#paint6_linear_20108_13094)"
    />
    <path
      d="M25.608 24.4727C25.6142 24.4764 25.5806 23.8451 25.5806 23.8451C25.5207 23.1979 25.5091 23.1777 25.3559 22.442C24.7129 19.3579 23.3013 17.18 21.5453 15.4899C20.0042 14.0075 18.391 13.0336 17.1009 12.4194C16.9354 12.3406 16.682 12.2429 16.5172 12.1715C14.2587 11.1909 12.1882 10.9479 10.274 10.6749C8.79887 10.4649 7.40615 10.2604 6.11722 9.79387C2.5539 8.50311 1.29428 6.17498 0.860769 5.02344C0.854052 5.05275 0.846115 5.08205 0.839398 5.11136C0.780172 5.36963 0.420543 6.14629 0.390625 6.53095L0.401005 15.6872C0.722779 16.4431 2.01781 17.445 2.60458 17.8773C3.43679 18.4903 4.42165 18.9397 5.38575 19.3201C7.69556 20.2317 10.1892 20.3037 12.6363 20.4759C17.1387 20.7934 21.9733 22.3901 25.5977 25.1114L25.608 24.4721V24.4727Z"
      fill="url(#paint7_linear_20108_13094)"
    />
    <path
      d="M23.8871 23.9331C23.8871 23.9331 22.3051 14.4606 10.3262 12.7656C10.3262 12.7656 17.8314 14.8935 19.3194 21.8382C19.1063 21.7167 21.6847 22.6197 23.8871 23.9331Z"
      fill="url(#paint8_linear_20108_13094)"
    />
    <path
      d="M9.97912 10.6771C1.97874 8.88685 2.00743 4.13718 1.78885 2.5625C1.78762 2.56738 1.05615 3.34709 0.673932 4.5371C0.38574 5.43465 0.396731 6.41585 0.390625 6.43356C0.393067 6.81822 0.396121 8.04426 0.396121 8.04426C1.76992 9.65801 4.27878 11.4684 8.57418 12.2493C17.2792 13.8319 17.775 15.1959 19.5383 16.3963C19.5383 16.3963 16.6997 11.334 9.97912 10.6758V10.6771Z"
      fill="url(#paint9_linear_20108_13094)"
    />
    <path
      opacity="0.2"
      d="M22.2885 28.9604C22.2885 28.9604 24.472 24.4979 19.3145 21.8438C19.3145 21.8438 23.5883 23.3236 25.5879 25.1126C25.5879 25.1126 24.9811 27.8034 22.2885 28.9604Z"
      fill="url(#paint10_linear_20108_13094)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20108_13094"
        x1="5.52502"
        y1="-0.184329"
        x2="12.2487"
        y2="19.4689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="1" stop-color="#0EE59B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_20108_13094"
        x1="16.5803"
        y1="0.955226"
        x2="30.3378"
        y2="21.3326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="1" stop-color="#0EE59B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_20108_13094"
        x1="-2.07171"
        y1="24.0636"
        x2="21.9997"
        y2="-0.894337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="1" stop-color="#0EE59B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_20108_13094"
        x1="14.545"
        y1="12.1221"
        x2="24.0132"
        y2="32.1667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="1" stop-color="#0EE59B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_20108_13094"
        x1="3.76389"
        y1="5.46733"
        x2="6.78258"
        y2="1.28304"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_20108_13094"
        x1="-3.16687"
        y1="7.66155"
        x2="30.0754"
        y2="28.4169"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_20108_13094"
        x1="26.647"
        y1="25.884"
        x2="-5.4199"
        y2="4.7275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#83DBEF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_20108_13094"
        x1="-1.60047"
        y1="11.3099"
        x2="25.5958"
        y2="18.2009"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#CFFFFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_20108_13094"
        x1="28.4279"
        y1="25.8485"
        x2="7.38441"
        y2="10.4167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFEFF" />
        <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_20108_13094"
        x1="-1.07171"
        y1="4.23304"
        x2="21.6344"
        y2="18.4106"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_20108_13094"
        x1="23.8174"
        y1="15.6676"
        x2="21.6871"
        y2="26.8546"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
